@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@font-face {
  font-family: "Gilroy-Heavy";
  src: url("assets/fonts/Gilroy-Heavy.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy-Heavy-Italic";
  src: url("assets/fonts/Gilroy-HeavyItalic.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy-Regular";
  src: url("assets/fonts/Gilroy-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy-Black";
  src: url("assets/fonts/Gilroy-Black.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy-Light";
  src: url("assets/fonts/Gilroy-Light.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy-Italic";
  src: url("assets/fonts/Gilroy-RegularItalic.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy-SemiBold";
  src: url("assets/fonts/Gilroy-SemiBold.ttf");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Gilroy-Heavy", "Gilroy-Regular", "Gilroy-Black", "Gilroy-Light",
    "Gilroy-RegularItalic", "Gilroy-SemiBold", "Gilroy-RegularItalic",
    "Gilroy-Heavy-Italic", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
